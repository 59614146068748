import Typography from 'typography'
import wordpress2013 from 'typography-theme-wordpress-2013'

wordpress2013.headerLineHeight = 1.1
wordpress2013.headerFontFamily = ['roboto slab']

wordpress2013.bodyFontFamily = ['roboto slab']

wordpress2013.overrideThemeStyles = () => {
  return {
    a: {
      color: '#78aabf',
      textDecoration: 'underline',
      fontFamily: 'roboto slab',

      // fontWeight: 500,
    },
    p: {
      fontFamily: 'roboto slab',
      fontSize: '21px',
    },
    li: {
      fontFamily: 'roboto slab',
    },
    h1: {
      lineHeight: '1.5em',
      color: '#555555',
    },
    h2: {
      marginTop: '50px',
      lineHeight: '1.5em',
      color: '#555555',
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontFamily: 'open sans',
      fontWeight: '100',
      lineHeight: '1.5em',
      fontSize: '30px',
      marginBottom: '15px',
      marginTop: '65px',
    },
    hr: {
      width: '30%',
      margin: '80px auto',
    },
    // strong: {
    //   fontWeight: '500',
    // },
    blockquote: {
      // fontSize: '1.5rem',
      padding: '1em 1.2em',
      margin: '1.5rem 0',
      lineHeight: '1.5em',
      borderLeftWidth: '10px',
      borderLeftStyle: 'solid',
      borderLeftColor: '#78abbe',
      paddingLeft: '1.25rem',
      backgroundColor: '#f6f5f4',
      fontFamily: 'roboto slab',
    },
  }
}
// console.log(wordpress2013)

const typography = new Typography(wordpress2013)

export default typography
