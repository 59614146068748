import React from 'react'
// import ClockIcon from 'react-icons/fa/clock-o'
// import TagIcon from 'react-icons/fa/tag'
// import OpenIcon from 'react-icons/fa/folder-open'

import typo from '../utils/typography'

const { rhythm } = typo
// console.log(typo)
// debugger

export default ({ node, className = `` }) => (
    <div css={{ marginTop: rhythm(-1 / 2) }} className={className}>
        <span style={{ marginRight: rhythm(1) }}>
            {/* <ClockIcon size={14} style={{ position: `relative`, bottom: 1 }} /> */}
            {` `}
            {node.date}
        </span>
        {/* {node.categories &&
            node.categories.map(category => (
                <span style={{ marginRight: rhythm(1) }} key={category.name}>
                    <OpenIcon
                        size={14}
                        style={{ position: `relative`, bottom: 1 }}
                    />
                    {` `}
                    {category.name}
                </span>
            ))}
        {node.tags &&
            node.tags.map(tag => (
                <span key={tag.name}>
                    <TagIcon
                        size={14}
                        style={{ position: `relative`, bottom: 1 }}
                    />
                    {` `}
                    {tag.name}
                </span>
            ))} */}
    </div>
)

export const query = graphql`
    fragment PostIcons on wordpress__POST {
        date(formatString: "MMMM DD, YYYY")
        categories {
            name
        }
    }
`
// export const query = graphql`
//     fragment PostIcons on wordpress__POST {
//         date(formatString: "MMMM DD, YYYY")
//         tags {
//             name
//         }
//         categories {
//             name
//         }
//     }
// `
